<template>
  <div class="base-node">
    <loader v-if="!isLoaded" />
    <div class="blur">
      <main-header :msisdn="msisdn" />
      <router-view v-if="isLoaded" :msisdn="msisdn" :user="user" :sim-item="simItem" :error-entity="ErrorEntity" />
      <main-footer />
    </div>
    <portal-target name="notification-block"></portal-target>
    <portal-target name="small-modal"></portal-target>
  </div>
</template>

<script>
import Loader from "./components/atomic/Loader/Loader.vue";
import MainHeader from "./components/main/MainHeader.vue";
import MainFooter from "./components/main/MainFooter.vue";

import network from "./managers/api/network.js";
import { setToken, removeToken } from "./utils/handlerAccessToken.js";
import redirect from "./utils/redirect.js";

export default {
  components: { Loader, MainHeader, MainFooter },
  data() {
    return {
      isLoaded: false,
      user: {},
      simItem: {},
      ErrorEntity: {},
    };
  },
  created() {
    this.ErrorEntity = Object.assign({}, this.ErrorEntity, { message: "Ошибка при авторизации", url: redirect(true) });
    this.checkAuth();
  },
  computed: {
    msisdn() {
      return "sim" in this.simItem ? this.simItem.sim.msisdn : "";
    },
  },
  methods: {
    async loadData() {
      try {
        const { data: user } = await network.me();
        this.user.dashboard_id = user.data.dashboard_id;
        const bodySim = { page: 1, last_page: 1, per_page: 30 };
        const simData = await network.listSims(this.user.dashboard_id, bodySim);
        const [sim] = simData.data.data.items;
        this.simItem = Object.assign({}, this.simItem, sim);
        this.$router.push("/");
        this.isLoaded = true;
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    async checkAuth() {
      const { error_description, code } = this.$route.query;
      if (error_description) {
        removeToken();
        this.isLoaded = true;
        this.$router.push("/500");
        this.ErrorEntity = Object.assign({}, this.ErrorEntity, {
          message: `Ошибка при авторизации: ${error_description}`,
          url: redirect(true),
        });
      } else if (this.$route.path === "/autologin/callback" && code) {
        try {
          const auth = await network.auth({ code });
          setToken(auth.data.access_token);
          this.loadData();
        } catch (e) {
          this.isLoaded = true;
          this.$router.push("/500");
          this.ErrorEntity = Object.assign({}, this.ErrorEntity, { message: "Ошибка при авторизации", url: redirect(true) });
        }
      } else if (Object.hasOwnProperty.call(localStorage, "access_token")) {
        setToken(localStorage.getItem("access_token"));
        this.loadData();
      } else {
        redirect();
      }
    },
  },
};
</script>

<style lang="less" src="./assets/less/app.less"></style>
