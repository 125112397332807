<template>
  <div class="logo" @click.prevent="update">
    <span class="logo__img"></span>
    <span class="logo__text">Автомотив M2M</span>
  </div>
</template>
<script>
export default {
  methods: {
    update() {
      window.location.reload();
    },
  },
};
</script>
