<template>
  <header class="lk-heade__wrapper">
    <div class="lk-main__header">
      <div class="lk-header">
        <header-log />
        <header-profile :msisdn="msisdn" />
      </div>
    </div>
  </header>
</template>

<script>
import HeaderLog from "../headers/HeaderLog.vue";
import HeaderProfile from "../headers/HeaderProfile.vue";
export default {
  components: { HeaderLog, HeaderProfile },
  props: { msisdn: { type: String, required: true } },
};
</script>
