<template>
  <portal to="notification-block">
    <div class="small-alert">
      <div class="close" @click="$emit('update:hasOpen', false)" />
      {{ message }}
    </div>
  </portal>
</template>
<script>
export default {
  props: {
    message: { type: String, required: true },
    hasOpen: { type: Boolean, required: true },
  },
  mounted() {
    this.idTimeout = setTimeout(() => {
      this.$emit("update:hasOpen", false);
    }, 5000);
  },
  beforeDestroy() {
    clearTimeout(this.idTimeout);
  },
};
</script>
<style lang="less" src="./SmallAlert.less"></style>
