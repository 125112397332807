<template>
  <div class="lk-single__item">
    <div class="lk-single__item-left">
      <div class="lk-single__item-volume">{{ dataPackage.volume }} Мб</div>
    </div>
    <div class="lk-single__item-right">
      <div class="lk-single__item-cost">{{ dataPackage.cost }} ₽</div>
      <button
        :class="['btn btn-s btn-normal', hasAttach ? 'loader' : '']"
        v-ripple
        @click.prevent="attachPackage"
        :disabled="hasDisabled"
      >
        Подключить
        <pre />
      </button>
    </div>
    <small-alert v-if="hasOpen" :message="message" :hasOpen.sync="hasOpen" />
    <small-modal v-if="hasOpenError" :message="messageEror" :hasOpen.sync="hasOpenError" />
  </div>
</template>
<script>
import SmallAlert from "../SmallAlert/SmallAlert.vue";
import SmallModal from "../SmallModal/SmallModal.vue";

import network from "../../../managers/api/network.js";
export default {
  components: { SmallAlert, SmallModal },
  props: {
    dataPackage: { type: Object, required: true },
    dashboardId: { type: [String, Number], required: true },
    simId: { type: [String, Number], required: true },
  },
  data() {
    return {
      hasAttach: false,
      hasOpen: false,
      message: "",
      messageEror: "",
      hasOpenError: false,
    };
  },
  computed: {
    hasDisabled() {
      return this.hasAttach || this.dataPackage.isDisabled;
    },
  },
  methods: {
    async attachPackage() {
      try {
        this.hasAttach = true;
        const { data: balance } = await network.getBalance(this.dashboardId, this.simId);
        if (Number(balance.amount || 0) < Number(this.dataPackage.cost)) {
          this.messageEror = "Недостаточно средств для проведения операции.";
          this.hasOpenError = true;
          this.hasAttach = false;
          return;
        }
        const res = await network.setTurboPackage(this.dashboardId, this.simId, this.dataPackage.id);
        if (res.data && res.data.status === "required service not found") {
          this.messageEror = "Основной пакет не подключен.";
          this.hasOpenError = true;
        } else if (res.data && res.data.status === "too much traffic left") {
          this.messageEror = "Операция невозможна. Остаток трафика составляет более 10% от базового объёма пакета.";
          this.hasOpenError = true;
        } else if (
          res.data &&
          res.data.status === "error" &&
          res.data.message.startsWith("Access to Launch Turbo is limited, because your previous start was at")
        ) {
          this.messageEror = `Вы уже активировали услугу, повторный вызов возможен через  ${res.data.time_left}`;
          this.hasOpenError = true;
        } else {
          this.message = "Услуга подключается.";
          this.hasOpen = true;
        }
        const services = await network.getServices(this.dashboardId, this.simId);
        this.$emit("updatePackage", services);
        this.hasAttach = false;
      } catch (e) {
        this.messageEror = "Не удалось выполнить запрос. Повторите попытку позже.";
        this.hasOpenError = true;
        this.hasAttach = false;
      }
    },
  },
};
</script>
