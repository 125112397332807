<template>
  <div class="empty-block skeleton-loader__bone" :style="measurableStyles"></div>
</template>
<script>
export default {
  props: {
    type: { type: String, required: false, default: "line" },
    height: { type: String, required: false, default: "" },
    maxHeight: { type: String, required: false, default: "" },
    maxWidth: { type: String, required: false, default: "" },
    minHeight: { type: String, required: false, default: "" },
    minWidth: { type: String, required: false, default: "" },
    width: { type: String, required: false, default: "" },
  },
  computed: {
    measurableStyles() {
      const styles = {};

      if (this.height) styles.height = this.height;
      if (this.minHeight) styles.minHeight = this.minHeight;
      if (this.minWidth) styles.minWidth = this.minWidth;
      if (this.maxHeight) styles.maxHeight = this.maxHeight;
      if (this.maxWidth) styles.maxWidth = this.maxWidth;
      if (this.width) styles.width = this.width;

      return styles;
    },
  },
};
</script>
<style lang="less" src="./SkeletonLoader.less"></style>
