<template>
  <div class="lk-block">
    <h4 class="lk-block__title">Доступный объем трафика</h4>
    <div class="svg-container">
      <chart-dount viewBox="0 0 36 36" class="svg-remaining" :values="chartData" :colors="chartColors" />
      <div class="lk-remaing" v-if="hasService">
        <div class="lk-remaing__label">Доступно</div>
        <div class="lk-remaing__number">{{ remaing }} {{ service.remaing.measure }}</div>
        <div class="lk-remaing__label">на {{ service.remaing.diffDays }} {{ strDay }}</div>
      </div>
      <div class="lk-remaing" v-else>
        <skeleton-loader />
      </div>
    </div>
  </div>
</template>

<script>
import ChartDount from "../charts/ChartDount.vue";
import SkeletonLoader from "../atomic/Loader/SkeletonLoader.vue";
export default {
  components: { ChartDount, SkeletonLoader },
  props: {
    service: { type: Object, required: true },
  },
  data() {
    return {
      chartColors: ["#BBC1C7", "#e30611"],
    };
  },
  computed: {
    chartData() {
      if ("volume" in this.service) {
        const volume = this.service.volume - this.service.currentVolume;
        return [volume <= 0 ? 0 : volume, Number(this.service.currentVolume)];
      } else {
        return [(1, 0)];
      }
    },
    hasService() {
      return "volume" in this.service;
    },
    strDay() {
      if (this.service.remaing.diffDays <= 4 && this.service.remaing.diffDays > 1) {
        return "дня";
      } else if (this.service.remaing.diffDays == 1) {
        return "день";
      } else {
        return "дней";
      }
    },
    remaing() {
      return this.service.remaing.value - parseInt(this.service.remaing.value)
        ? this.service.remaing.value
        : Math.trunc(this.service.remaing.value);
    },
  },
};
</script>
<style lang="less">
.empty__service {
  width: 70%;
  height: 50%;
}
</style>
