<template>
  <vue-scrollbar class="lk-main__container">
    <remaing :service="service" />
    <sales
      :services="touchServices"
      :packages="packages"
      :dashboard-id="user.dashboard_id"
      :sim-id="simItem.id"
      :is-loaded="isLoaded"
      @updatePackage="updatePackage"
      @addPackage="addPackage"
    />
    <payment :msisdn="msisdn" :balance="balance" :isLoaded="isLoaded" />
  </vue-scrollbar>
</template>
<script>
import dayjs from "dayjs";
import VueScrollbar from "vue-perfect-scrollbar";
import Sales from "../../components/block/Sales.vue";
import Remaing from "../../components/block/Remaing.vue";
import Payment from "../../components/block/Payment.vue";

import network from "../../managers/api/network.js";
import { parseDate } from "../../helpers/date/dateIndex.js";
import { convertByte } from "../../helpers/convert/converIndex.js";

const TS_DATA_PACKAGE = "ts.data_package";
const ONE_TIME = "one_time";
const STATUS_ENABLED = "enabled";
const STATUS_DISABLE_ERROR = "disable_error";
const GB = 1024 * 1024 * 1024;
const DEFAULT_SERVICES_ID = [
  10251, 10255, 10253, 6224, 6220, 7760, 7763, 6216, 11704, 11706, 11708, 11710, 11712, 11714, 11716, 11718,
];

export default {
  props: {
    user: { type: Object, required: true },
    simItem: { type: Object, required: true },
    msisdn: { type: String, required: true },
  },
  components: { Sales, Remaing, Payment, VueScrollbar },
  data() {
    return {
      balance: "",
      service: {},
      touchServices: [],
      packages: [],
      isLoaded: false,
    };
  },
  computed: {
    idSim() {
      return "sim" in this.simItem ? this.simItem.id : "";
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getDiffDays(hasPeriod, attachTime, periodEnd, periodKey) {
      let days;

      if (hasPeriod) {
        days = dayjs(periodEnd).diff(dayjs(), "day", true);
      } else {
        const periodParams = this.getPeriod(periodKey);
        const countCompletedPeriods = dayjs().diff(dayjs(attachTime), periodParams.periodName);

        days = dayjs(attachTime)
          .add(countCompletedPeriods + periodParams.count, periodParams.periodName)
          .diff(dayjs(), "day", true);
      }

      return Math.ceil(days);
    },
    getPeriod(period) {
      switch (period) {
        case "daily":
          return { count: 1, periodName: "day" };
        case "three_daily":
          return { count: 3, periodName: "day" };
        case "three_monthly":
          return { count: 3, periodName: "month" };
        case "six_monthly":
          return { count: 6, periodName: "month" };
        case "yearly":
          return { count: 1, periodName: "year" };
        default:
          return { count: 1, periodName: "month" };
      }
    },
    async loadData() {
      const measure = {
        "Российский рубль": "₽",
      };
      try {
        const [{ data: balance }, services, listServices] = await Promise.all([
          network.getBalance(this.user.dashboard_id, this.idSim),
          network.getServices(this.user.dashboard_id, this.idSim),
          network.listLounchServices(this.user.dashboard_id),
        ]);

        let isDisabledTurbo = false;
        const m = measure[balance.unit_of_measure] ? measure[balance.unit_of_measure] : "";
        this.balance = balance.amount ? `${Number(balance.amount).toFixed(2)} ${m}` : "";

        isDisabledTurbo = this.setService(services);
        const hasToggle = services.data.data.items.some(
          (s) => ["enabling", "disabling"].includes(s.status) && DEFAULT_SERVICES_ID.includes(s.contract_service.service.base_id),
        );
        listServices.data.data.items.forEach((item) => {
          const { self_use } = item;
          const { parameters, base_service, charging_type } = item.service;
          if (base_service.key === TS_DATA_PACKAGE && parameters.volume > 0 && charging_type === ONE_TIME && self_use) {
            this.touchServices.push({
              id: item.id,
              volume: `+${parameters.volume}`,
              cost: parameters.cost,
              isDisabled: isDisabledTurbo,
            });
          } else if (base_service.key === TS_DATA_PACKAGE && DEFAULT_SERVICES_ID.includes(item.service.base_id) && self_use) {
            const activedService = services.data.data.items.find((s) => s.contract_service.id === item.id);
            this.packages.push({
              id: item.id,
              name: item.service.name,
              volume: parameters.volume,
              cost: parameters.cost,
              hasToggle: activedService ? ["enabling", "disabling"].includes(activedService.status) : false,
              hasEnabled: hasToggle ? true : activedService ? activedService.status === "enabled" : false,
            });
          }
        });

        this.isLoaded = true;
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    setService(services) {
      const status = [STATUS_ENABLED, STATUS_DISABLE_ERROR];
      let isDisabledTurbo = false;
      services.data.data.items.forEach((item) => {
        const { base_service, parameters, charging_period } = item.contract_service.service;

        if (base_service.key === TS_DATA_PACKAGE && status.includes(item.status)) {
          const service = {};
          const hasPeriod = item.period_end && parseDate(item.period_end) >= Date.now();
          service.volume = parameters.volume;
          const biteVolume = parameters.volume * 1024 * 1024;
          const biteValue = item.value != -1 && hasPeriod ? item.value : biteVolume;

          isDisabledTurbo = biteValue / biteVolume > 0.1;

          if (biteVolume < GB || biteValue < GB) {
            service.remaing = item.value != -1 && hasPeriod ? convertByte(item.value, 2) : convertByte(biteVolume, 2);
          } else {
            service.remaing = item.value != -1 && hasPeriod ? convertByte(item.value, 3) : convertByte(biteVolume, 3);
          }

          const { value } = item.value != -1 && hasPeriod ? convertByte(item.value, 2, 0) : convertByte(biteVolume, 2, 0);
          service.currentVolume = value || 0;
          service.remaing.diffDays = this.getDiffDays(hasPeriod, item.attach_time, item.period_end, charging_period);

          this.service = Object.assign({}, this.service, service);
        }
      });

      return isDisabledTurbo;
    },

    addPackage({ packageId, hasLoaded }) {
      const packageIdx = this.packages.findIndex((p) => p.id === packageId);
      if (packageIdx !== -1) {
        this.packages.splice(packageIdx, 1, { ...this.packages[packageIdx], hasToggle: hasLoaded, hasEnabled: hasLoaded });
      }
    },
    updatePackage(services) {
      const isDisabledTurbo = this.setService(services);
      this.touchServices.forEach((s) => (s.isDisabled = isDisabledTurbo));
    },
  },
};
</script>

<style lang="less" src="./lk.less"></style>
