<template>
  <footer id="version_app_js" data-version="table_version_22">
    <div class="row fixed">
      <div class="copy">
        <p class="fz">© {{ year }}, ПАО МТС 18+</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      hash: "",
    };
  },
  computed: {
    year() {
      const year = new Date().getFullYear();
      return year > 2018 ? year : 2018;
    },
  },
};
</script>
