import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import PortalVue from "portal-vue";
import Ripple from "vue-ripple-directive";

Ripple.color = "rgba(255, 255, 255, 0.2)";

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.directive("ripple", Ripple);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
