<template>
  <div class="lk-main__container">
    <h1>{{ message }}. <a v-if="hasLink" :href="ErrorEntity.url">Попробуйте еще раз!</a></h1>
  </div>
</template>
<script>
export default {
  props: {
    ErrorEntity: { type: Object, required: true },
  },
  computed: {
    message() {
      return this.ErrorEntity.message || "Некорректные данные";
    },
    hasLink() {
      return !!this.ErrorEntity.url;
    },
  },
};
</script>
