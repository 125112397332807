<template>
  <portal to="small-modal">
    <div class="wrapper-fixed">
      <vue-scrollbar class="small-modal">
        <h2>{{ message }}</h2>
        <button class="btn btn-s btn-normal" @click="$emit('update:hasOpen', false)">Закрыть</button>
      </vue-scrollbar>
    </div>
  </portal>
</template>
<script>
import VueScrollbar from "vue-perfect-scrollbar";
export default {
  props: {
    message: { type: String, required: true },
    hasOpen: { type: Boolean, default: false },
  },
  components: { VueScrollbar },
};
</script>
<style lang="less" src="./SmallModal.less"></style>
