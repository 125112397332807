import { render, staticRenderFns } from "./SmallModal.vue?vue&type=template&id=9f72a326&"
import script from "./SmallModal.vue?vue&type=script&lang=js&"
export * from "./SmallModal.vue?vue&type=script&lang=js&"
import style0 from "./SmallModal.less?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports