<template>
  <div class="lk-block">
    <div class="lk-balance">
      <div class="lk-balance__text">Ваш баланс:</div>

      <div v-if="hasLoaded" class="lk-balance__sum">{{ balance }}</div>

      <skeleton-loader v-else />
    </div>

    <div class="lk-paytitle">Пополнить:</div>

    <div class="lk-payments">
      <button class="btn btn-s btn-normal" v-ripple @click.prevent="insertSum(100)">100 ₽</button>

      <button class="btn btn-s btn-normal" v-ripple @click.prevent="insertSum(500)">500 ₽</button>

      <button class="btn btn-s btn-normal" v-ripple @click.prevent="insertSum(1000)">1000 ₽</button>
    </div>

    <div class="lk-payment">
      <form @submit.prevent="pay" class="lk-payment__form">
        <text-input v-model="amount" :errors="errors" class="lk-payment__inp" placeholder="Сумма" />

        <button class="btn btn-s btn-normal lk-payment__btn" v-ripple :disabled="canPay">Оплатить</button>
      </form>
    </div>

    <div class="lk-payment__title">От 100 до 3000 ₽</div>
  </div>
</template>

<script>
import SkeletonLoader from "../atomic/Loader/SkeletonLoader.vue";
import TextInput from "../Inputs/TextInput.vue";
export default {
  props: {
    msisdn: { type: String, required: true },
    balance: { type: String, required: true },
  },
  components: { SkeletonLoader, TextInput },
  data() {
    return {
      amount: "",
      errors: [],
    };
  },
  computed: {
    canPay() {
      return !(this.amount >= 100);
    },
    hasLoaded() {
      return !!this.balance;
    },
  },
  methods: {
    insertSum(sum) {
      this.amount = sum;
    },
    pay() {
      this.errors = [];
      if (
        (this.amount && typeof Number(this.amount) === "number" && Number(this.amount) >= 0 && this.amount > 3000) ||
        /\./.test(this.amount)
      ) {
        this.errors = ["Введите целое положительное число больше 100, но меньше 3000"];
        return;
      }
      window.location.href = `https://pay.mts.ru/refill/?serviceName=mts&phone=${this.$props.msisdn.slice(1)}&amount=${
        this.amount
      }`;
    },
  },
};
</script>

<style>
.empty__balance {
  width: 65%;
  height: 100%;
}
</style>
