<template>
  <div>
    <input
      type="text"
      :class="{ 'msg-error__block': errors.length }"
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <transition name="decent">
      <span v-for="(error, i) in errors" :key="i" class="msg-error">{{ error }}</span>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: [Number, String], required: true },
    errors: { type: Array, required: false, default: () => [] },
  },
  inheritAttrs: false,
};
</script>
