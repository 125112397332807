<template>
  <div class="lk-block">
    <vue-scrollbar class="wrap-scroll">
      <template v-if="packages.length">
        <h4 class="lk-block__title">Интернет-пакеты</h4>
        <div class="lk-single__item" v-for="pakage in packages" :key="pakage.id">
          <div class="lk-single__label">{{ pakage.name }}</div>
          <div class="lk-single__item-left">
            <div class="lk-single__item-volume">{{ convertVolume(pakage.volume) }} Гб</div>
          </div>
          <div class="lk-single__item-right">
            <div class="lk-single__item-cost">{{ pakage.cost }} ₽</div>
            <button
              class="btn btn-s btn-normal"
              :class="{ loader: pakage.hasToggle }"
              v-ripple
              :disabled="pakage.hasEnabled"
              @click="addPackage(pakage.id, pakage)"
            >
              Подключить
              <pre />
            </button>
          </div>
        </div>
      </template>
      <h4 class="lk-block__title">Пополнить объем основного пакета</h4>
      <div class="lk-single">
        <service-list
          v-for="serv in services"
          :key="serv.id"
          :data-package="serv"
          :dashboard-id="dashboardId"
          :sim-id="simId"
          v-on="$listeners"
        />
        <div class="mt-20" v-if="!isLoaded">
          <skeleton-loader
            v-for="(skeleton, idx) in skeletons"
            :key="idx"
            :width="skeleton.width"
            :min-height="skeleton.minHeight"
          />
        </div>
      </div>
    </vue-scrollbar>
    <small-modal v-if="hasOpenError" :message="messageEror" :hasOpen.sync="hasOpenError" />
  </div>
</template>

<script>
import VueScrollbar from "vue-perfect-scrollbar";
import ServiceList from "../atomic/Service/ServiceList.vue";
import SkeletonLoader from "../atomic/Loader/SkeletonLoader.vue";
import SmallModal from "../atomic/SmallModal/SmallModal.vue";
import network from "../../managers/api/network.js";
import ServiceErrors from "./ServiceErrors.json";

export default {
  props: {
    services: { type: Array, required: true },
    packages: { type: Array, required: true },
    dashboardId: { type: [String, Number], required: true },
    simId: { type: [String, Number], required: true },
    isLoaded: { type: Boolean, required: true },
  },
  components: {
    ServiceList,
    VueScrollbar,
    SkeletonLoader,
    SmallModal,
  },
  data() {
    return {
      skeletons: [
        { width: "65%", minHeight: "20px" },
        { width: "50%", minHeight: "20px" },
        { width: "100%", minHeight: "20px" },
        { width: "70%", minHeight: "20px" },
        { width: "35%", minHeight: "20px" },
      ],
      hasOpenError: false,
      messageEror: "",
    };
  },

  methods: {
    convertVolume(volume) {
      return (volume / 1024).toFixed(2);
    },

    async addPackage(packageId, dataPackage) {
      this.$emit("addPackage", { packageId, hasLoaded: true });
      try {
        const { data: balance } = await network.getBalance(this.dashboardId, this.simId);
        if (Number(balance.amount || 0) < Number(dataPackage.cost)) {
          this.messageEror = "Недостаточно средств для проведения операции.";
          this.hasOpenError = true;
          this.$emit("addPackage", { packageId, hasLoaded: false });
          return;
        }

        await network.addPackage(this.dashboardId, this.simId, packageId);
      } catch (r) {
        if (r.response.data.error && r.response.data.error.message) {
          this.messageEror = ServiceErrors[r.response.data.error.message];
        } else if (r.response.status == 403) {
          this.messageEror = "Доступ запрещен.";
        } else {
          this.messageEror = "Не удалось выполнить запрос. Повторите попытку позже.";
        }
        this.hasOpenError = true;
        this.$emit("addPackage", { packageId, hasLoaded: false });
      }
    },
  },
};
</script>
<style lang="less">
.lk-single__label {
  width: 100%;
  font-size: 24px;
}
</style>
