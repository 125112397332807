import axios from "axios";

import redirect from "../../utils/redirect.js";
import { setToken } from "../../utils/handlerAccessToken.js";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const requestsQueue = {};
const urlCalledFunc = {};
const apiEndpoint = "/api/auto/v0/";

const getUrl = (path) => {
  return apiEndpoint + path;
};

axios.interceptors.response.use(
  (response) => {
    if (response.config.url) {
      delete requestsQueue[response.config.url];
    }

    if (response.headers["x-token"] && response.headers["x-token"] !== localStorage.getItem("access_token")) {
      setToken(response.headers["x-token"]);
    }

    return response;
  },
  (error) => {
    const {
      config,
      response: { status, data },
    } = error;
    if (status === 401 && data.error.message === "Unauthorized") {
      redirect();
    }
    if (config && config.url) {
      delete requestsQueue[config.url];
    }
    return Promise.reject(error);
  },
);

export default {
  cancelRequests: function (requestName = []) {
    requestName.forEach((req) => {
      if (requestsQueue[urlCalledFunc[req]]) {
        requestsQueue[urlCalledFunc[req]]("Cancel request");
        delete requestsQueue[urlCalledFunc[req]];
        delete urlCalledFunc[req];
      }
    });
  },

  execute: function ({ url: rest_url, data = {}, method = "get", additionalParams }) {
    const url = getUrl(rest_url);

    if (!(typeof data === "object")) {
      data = {};
    }
    const config = {
      method,
      url,
      data,
      cancelToken: new axios.CancelToken(function (c) {
        requestsQueue[url] = c;
      }),
    };
    if (process.env.VUE_APP_URL_API) {
      config.baseURL = process.env.VUE_APP_URL_API;
    }

    if (additionalParams && additionalParams.responseType) {
      config.responseType = additionalParams.responseType;
    }

    urlCalledFunc[additionalParams && additionalParams.calledFunc] = url;

    return axios(config);
  },
  auth(data) {
    return this.execute({
      url: "auth",
      method: "post",
      data,
      calledFunc: "auth",
    });
  },
  me() {
    return this.execute({
      url: "me",
      calledFunc: "me",
    });
  },

  logout() {
    return this.execute({
      url: "logout",
      calledFunc: "logout",
    });
  },
  listSims(dashboardId, data) {
    return this.execute({
      url: `dashboards/${dashboardId}/sim_cards`,
      data,
      method: "post",
      calledFunc: "listSims",
    });
  },
  getBalance(dashboardId, idSim) {
    return this.execute({
      url: `dashboards/${dashboardId}/sim_cards/${idSim}/get_balance`,
      calledFunc: "getBalance",
    });
  },
  getServices(dashboardId, idSim) {
    return this.execute({
      url: `dashboards/${dashboardId}/sim_cards/${idSim}/services`,
      calledFunc: "getServices",
    });
  },
  listLounchServices(dashboardId) {
    return this.execute({
      url: `dashboards/${dashboardId}/contracts/available_services`,
      calledFunc: "listLounchServices",
    });
  },
  setTurboPackage(dashboardId, simId, seriveId) {
    return this.execute({
      url: `dashboards/${dashboardId}/sim_cards/${simId}/service/${seriveId}/launch_turbo`,
      calledFunc: "setTurboPackage",
    });
  },
  addPackage(dashboardId, simId, seriveId) {
    return this.execute({
      url: `dashboards/${dashboardId}/sim_cards/${simId}/service/${seriveId}/add`,
      calledFunc: "addPackage",
    });
  },
};
