import axios from "axios";

export function setToken(accessToken) {
  localStorage.setItem("access_token", accessToken);
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

export function removeToken(accessToken) {
  localStorage.removeItem("access_token", accessToken);
  axios.defaults.headers.common["Authorization"] = "";
}
