<template>
  <svg viewBox="0 0 36 36">
    <g v-for="(c, index) in calculateChartData" :key="index">
      <circle
        :cx="cx"
        :cy="cy"
        :r="radius"
        :stroke="colors[index]"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="calculateStrokeDashOffset(c.value, circumference)"
        fill="transparent"
        :transform="c.degrees"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    values: { type: Array, required: true },
    colors: { type: Array, required: true },
    offSet: { type: Number, required: false, default: -90 },
    cx: { type: Number, required: false, default: 18 },
    cy: { type: Number, required: false, default: 18 },
    radius: { type: Number, required: false, default: 16 },
    strokeWidth: { type: Number, required: false, default: 3 },
  },
  data() {
    return {
      angleOffset: this.offSet,
    };
  },
  computed: {
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    dataTotal() {
      return this.values.reduce((acc, val) => acc + val);
    },
    calculateChartData() {
      return this.values.map((dataVal) => {
        const data = {
          degrees: `rotate(${this.angleOffset}, ${this.cx}, ${this.cy})`,
          value: dataVal,
        };
        this.recountAngleOffset(dataVal);
        return data;
      });
    },
  },
  methods: {
    calculateStrokeDashOffset(dataVal, circumference) {
      const strokeDiff = this.dataPercentage(dataVal) * circumference;
      return circumference - strokeDiff;
    },
    dataPercentage(dataVal) {
      const precent = dataVal / this.dataTotal;
      return isNaN(precent) ? 1 : precent;
    },
    recountAngleOffset(dataVal) {
      this.angleOffset = this.dataPercentage(dataVal) * 360 + this.angleOffset;
    },
  },
};
</script>
