export default function redirect(hasUrl = false) {
  const gw = process.env.VUE_APP_OAUTH_GW;
  const scope = encodeURIComponent(process.env.VUE_APP_SCOPE);
  const redirect = encodeURIComponent(process.env.VUE_APP_REDIRECT_URI);
  const clientId = process.env.VUE_APP_CLIENT_ID;
  const state = Date.now() + Math.random();

  const url = `${gw}client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirect}&response_type=code`;
  if (hasUrl) {
    return url;
  } else {
    document.location.href = url;
  }
}
