export default (bytes, factor = 2, dm = 1) => {
  if (bytes < 0) return "0.00";

  const k = 1024;
  const sizes = ["Байт", "Кб", "Мб", "Гб", "Тб", "Пб"];

  const res = parseFloat(bytes / Math.pow(k, factor)).toFixed(dm);

  return { value: res, measure: sizes[factor] };
};
