<template>
  <div class="lk-client" ref="dropdwon">
    <div class="client-avatar" v-ripple="'rgba(0, 0, 0, 0.15)'" @click.prevent="toggleDropdown">
      <svg-icon name="user" class="client-avatar__svg" />
    </div>
    <transition name="fade" :duration="100">
      <div class="lk-dropdown" v-if="isOpened">
        <ul class="lk-dropdown__list">
          <li class="lk-dropdown__item">
            <a href="" class="lk-dropdown__link no-link" @click.prevent>
              <div class="client-avatar icon-wrapper">
                <svg-icon name="user" class="client-avatar__svg" />
              </div>
              <span v-if="hasLoaded">
                {{ maskPhone }}
              </span>
              <skeleton-loader v-else min-height="25px" />
            </a>
          </li>
          <li class="lk-dropdown__item">
            <a href="" class="lk-dropdown__link" @click.prevent="logout">
              <div class="icon-wrapper">
                <svg-icon name="logout" class="client-logout__svg" />
              </div>
              <span>Выход</span>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import SvgIcon from "../atomic/Icon/SvgIcon.vue";
import SkeletonLoader from "../atomic/Loader/SkeletonLoader.vue";
import network from "../../managers/api/network.js";
export default {
  components: { SvgIcon, SkeletonLoader },
  props: { msisdn: { type: String, required: true } },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    hasLoaded() {
      return !!this.msisdn;
    },
    maskPhone() {
      const chunks = this.msisdn
        .replace(/\D/g, "")
        .match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/)
        .slice(1);
      return `+${chunks[0]} ${chunks[1]} ${chunks[2]}-${chunks[3]}-${chunks[4]}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("click", this.closePanel);
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closePanel);
  },
  methods: {
    closePanel(e) {
      if (!this.isOpened) return;
      const el = this.$refs.dropdwon;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isOpened = false;
      }
    },
    toggleDropdown() {
      this.isOpened = !this.isOpened;
    },
    logout() {
      network.logout().finally(() => {
        localStorage.clear();
        window.location.href = "https://login.mts.ru/amserver/UI/Logout?goto=https://mts.ru";
      });
    },
  },
};
</script>
